:root {
  --footer-font-size: 0.7em;
}
footer {
  background-color: black;
  height: var(--footer-height);
  font-size: var(--footer-font-size);
  text-align: center;
}
.footer_wrapper {
  display: flex;
  flex-direction: column;
}
.footer_links {
  display: flex;
  justify-content: center;
  height: 40px;
}
.footer_link {
  font-size: var(--footer-font-size);
  color: grey;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.footer_link:hover {
  color: var(--main-font-colour);
  transition: all 0.8s ease-in-out;
}
.spacer {
  padding: 0 1em;
}
