:root {
  --letter-spacing: 30px;
  --title-padding: 0;
}

.logo_link {
  outline: none;
  text-decoration: none;
  font-size: 1.6em;
  line-height: 1;
  letter-spacing: 3px;
  padding: 2px;
}

.logoSubText {
  color: var(--main-font-colour);
  text-transform: lowercase;
  text-align: center;
  font-size: 0.85em;
  letter-spacing: 3px;
  padding: 2px;
}

/* Ilin */
.link--ilin {
  font-weight: normal;
  text-transform: lowercase;
  line-height: 1.2;
  overflow: hidden;
  color: var(--main-font-colour);
}

.link--ilin span {
  position: relative; /* NB dont change this */
  display: inline-block;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

.link--ilin:hover span:first-of-type {
  color: var(--burnt-orange);
}

.link--ilin:hover span:last-of-type {
  color: var(--secondary-bg-colour);
}

.link--ilin span::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 80%;
  background: var(--burnt-orange);
  line-height: 1.2;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.link--ilin span:last-of-type::before {
  background: var(--secondary-bg-colour);
}

.link--ilin:hover span:last-of-type::before,
.link--ilin span:first-of-type::before {
  -webkit-transform: translate3d(0, -150%, 0);
  transform: translate3d(0, -150%, 0);
}

.link--ilin:hover span:first-of-type::before,
.link--ilin span:last-of-type::before {
  -webkit-transform: translate3d(0, 150%, 0);
  transform: translate3d(0, 150%, 0);
}
